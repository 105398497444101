/**
 * @generated SignedSource<<fcaa3c776f553f1c4a15bddcc5692015>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentClass = "CONTENT_CLASS_FEATURE_ANNOUNCEMENT_MODAL" | "CONTENT_CLASS_MODAL" | "CONTENT_CLASS_NEXT_BEST_ACTIONS" | "CONTENT_CLASS_UNKNOWN" | "%future added value";
export type ObjectDismissedQuery$variables = {
  companyId: string;
  contentClass: ContentClass;
  contentId: string;
  userId: string;
};
export type ObjectDismissedQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ObjectDismissedFragment_query">;
};
export type ObjectDismissedQuery = {
  response: ObjectDismissedQuery$data;
  variables: ObjectDismissedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentClass"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ObjectDismissedQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ObjectDismissedFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ObjectDismissedQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          },
          {
            "kind": "Variable",
            "name": "objectClass",
            "variableName": "contentClass"
          },
          {
            "kind": "Variable",
            "name": "objectId",
            "variableName": "contentId"
          },
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "concreteType": "FindUserContentVisibilityResponse",
        "kind": "LinkedField",
        "name": "contentVisibility",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isVisible",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "030348961238922811a989b108326e18",
    "id": null,
    "metadata": {},
    "name": "ObjectDismissedQuery",
    "operationKind": "query",
    "text": "query ObjectDismissedQuery(\n  $userId: ID!\n  $companyId: ID!\n  $contentClass: ContentClass!\n  $contentId: String!\n) {\n  ...ObjectDismissedFragment_query\n}\n\nfragment ObjectDismissedFragment_query on Query {\n  contentVisibility(userId: $userId, companyId: $companyId, objectClass: $contentClass, objectId: $contentId) {\n    isVisible\n  }\n}\n"
  }
};
})();

(node as any).hash = "24171cea5da04d48d233c1b9de395991";

export default node;

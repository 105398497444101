/**
 * @generated SignedSource<<cb82377fabffd1922a6de633ec891822>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentClass = "CONTENT_CLASS_FEATURE_ANNOUNCEMENT_MODAL" | "CONTENT_CLASS_MODAL" | "CONTENT_CLASS_NEXT_BEST_ACTIONS" | "CONTENT_CLASS_UNKNOWN" | "%future added value";
export type PreferenceType = "PREFERENCE_TYPE_ORDERING" | "PREFERENCE_TYPE_UNKNOWN" | "PREFERENCE_TYPE_VISIBILITY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FindContentSettingFragment_query$data = {
  readonly contentSetting: {
    readonly contentSetting: {
      readonly companyId: string | null;
      readonly id: string;
      readonly objectClass: ContentClass;
      readonly objectId: string;
      readonly preferenceType: PreferenceType;
      readonly preferenceValue: string;
      readonly userId: string | null;
    };
  } | null;
  readonly " $fragmentType": "FindContentSettingFragment_query";
};
export type FindContentSettingFragment_query$key = {
  readonly " $data"?: FindContentSettingFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"FindContentSettingFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "objectClass"
    },
    {
      "kind": "RootArgument",
      "name": "objectId"
    },
    {
      "kind": "RootArgument",
      "name": "preferenceType"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FindContentSettingFragment_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        },
        {
          "kind": "Variable",
          "name": "objectClass",
          "variableName": "objectClass"
        },
        {
          "kind": "Variable",
          "name": "objectId",
          "variableName": "objectId"
        },
        {
          "kind": "Variable",
          "name": "preferenceType",
          "variableName": "preferenceType"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "FindSpecificUserContentSettingConnection",
      "kind": "LinkedField",
      "name": "contentSetting",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserContentSetting",
          "kind": "LinkedField",
          "name": "contentSetting",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "objectId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "objectClass",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferenceType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferenceValue",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2f6a469d9682ea87cb2d79ade21db3cc";

export default node;

/**
 * @generated SignedSource<<61f94460053fff39674ebc13d6cf744d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ObjectDismissedFragment_query$data = {
  readonly contentVisibility: {
    readonly isVisible: boolean;
  } | null;
  readonly " $fragmentType": "ObjectDismissedFragment_query";
};
export type ObjectDismissedFragment_query$key = {
  readonly " $data"?: ObjectDismissedFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectDismissedFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "contentClass"
    },
    {
      "kind": "RootArgument",
      "name": "contentId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectDismissedFragment_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        },
        {
          "kind": "Variable",
          "name": "objectClass",
          "variableName": "contentClass"
        },
        {
          "kind": "Variable",
          "name": "objectId",
          "variableName": "contentId"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "FindUserContentVisibilityResponse",
      "kind": "LinkedField",
      "name": "contentVisibility",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVisible",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "192e84e39bd6fde3f8000b89f6abe0a8";

export default node;
